<template>
	<div class="headerg">
		<div
			class="pd_header_pc flex align_items_c"
			:class="{ headergscrolled: true }"
		>
			<div
				class="tab_logo pointer"
				@click="
					$router.push({
						path: '/',
					})
				"
			>
				<img src="https://zy.metaera.media/assets/images/logo_w.png" alt="" />
			</div>
			<div class="align_items_c justify_content_sb tab_action">
				<div class="navList_first_pd flex align_items_c">
					<div
						class="tab_actionItem flex pointer"
						v-for="(item, index) in navList"
						:key="index"
						@click="toPath(item, index)"
						:class="{ active: currentPath == item.path }"
					>
						<span>{{ item.name }}</span>
						<div class="tab_actionItem_tag" v-if="item.name == '2025'"
							><span>new</span></div
						>
						<div class="tab_actionItem_line"></div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="pd_header_mobile flex align_items_c"
			:class="{ headergscrolled: true }"
		>
			<div class="tab_btn">
				<img
					src="https://zy.metaera.media/assets/images/event/48.png"
					@click="showList()"
					alt=""
				/>
			</div>
			<div class="tab_logo">
				<img
					src="https://zy.metaera.media/assets/images/logo_w.png"
					@click="goHome()"
					alt=""
				/>
			</div>
		</div>

		<div class="pd_header_list" v-show="isShowList">
			<div class="pd_header_list_btn" @click="hideList()">
				<img src="https://zy.metaera.media/assets/images/event/42.png" alt="" />
			</div>
			<div class="tab_actionItem_line_2"></div>
			<div class="pd_header_list_1">
				<div
					class="tab_actionItem_pd flex pointer"
					v-for="(item, index) in navList"
					:key="index"
					@click="toPath(item, index)"
					:class="{ active: currentPath == index }"
				>
					<span>{{ item.name }}</span>
					<div class="tab_actionItem_line_1"></div>
					<div class="tab_actionItem_line_3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { number } from "echarts";
export default {
	name: "",
	props: {
		currentPath: "",
	},
	created() {},
	data() {
		return {
			currentIndex: 0,
			isShowList: false,
			navList: [
				{
					name: "首页",
					path: "CryptoFi",
				},
				{
					name: "2025",
					path: "CryptoFi2025",
				},
				{
					name: "2024",
					path: "CryptoFi2024",
				},
				{
					name: "2023",
					path: "CryptoFi2023",
				},
			],
		};
	},
	methods: {
		toPath(item, index) {
			console.log(index);
			this.currentIndex = index;
			this.$router.push({ path: item.path });
			this.isShowList = false;
		},
		goHome() {
			this.$router.push({
				path: "/",
			});
		},
		showList() {
			this.isShowList = true;
		},
		hideList() {
			this.isShowList = false;
		},
		showUrl() {
			window.open("https://lu.ma/hszom0hw", "");
		},
		changeImage(imageKey) {
			this.currentImage = this.images[imageKey];
		},
	},
	watch: {
		"$route.name": function (n) {
			console.log("path", n);

			this.showHeader = n == "/";
			this.currentPath = n;
		},
	},
};
</script>

<style scoped>
.headergscrolled {
	background-color: #4930ce !important;
	color: white;
}
.pd_header_mobile {
	display: none;
}
.pd_header_pc {
	display: flex;
	height: 80px;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	z-index: 100;
	position: fixed;
	background: #1e1e1e;
}
.tab_actionItem_tag {
	position: absolute;
	top: -13px;
	right: -26px;
	z-index: 2;
	opacity: 1;
}

.tab_actionItem_tag span {
	background: #f63535;
	padding-left: 2px;
	padding-right: 2px;
	padding-bottom: 2px;
	width: 20px;
	height: 14px;
	font-size: 10px;
	font-weight: 400 !important;
	display: inline-block;
	text-align: center;
	align-content: center;
	align-items: center;
	border-radius: 6px;
}
.tab_action {
	position: absolute;
	right: 250px;
}
.tab_actionItem {
	position: relative;
	width: auto;
	margin-left: 40px;
	margin-top: 20px;
	height: 50px;
	display: flex;
	flex-direction: column;
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
}
.tab_actionItem span {
	font-weight: normal;
	color: white;
}

.tab_actionItem .tab_actionItem_line {
	display: none;
}

.navList_first_pd .active span {
	font-weight: bold;
}

.navList_first_pd .active .tab_actionItem_line {
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
	margin-top: 10px;
	width: 50px;
	height: 3px;
	background: white;
}
.tab_logo {
	margin-left: 10%;
}

.tab_logo img {
	height: 30px;
}

.tab_btn {
	position: absolute;
	right: 50px;
}

.tab_btn img {
	height: 45px;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.headergscrolled {
		background-color: #333333 !important;
	}
	.pd_header_mobile {
		display: flex;
		height: 50px;
		top: 0px;
		left: 0;
		width: calc(100vw);
		margin: 0 auto;
		z-index: 100;
		position: fixed;
	}
	.pd_header_pc {
		display: none;
	}

	.tab_logo {
		margin-left: -0px;
		width: 100%;
		text-align: center;
	}

	.tab_logo img {
		height: 20px;
	}
	.tab_btn {
		position: absolute;
		left: 20px;
		right: 80vw;
	}

	.tab_btn img {
		height: 20px;
	}
	.pd_header_list {
		width: 100%;
		height: 100vh;
		background: white;
		display: flex;
		flex-direction: column;
		top: 0px;
		left: 0;
		margin: 0 auto;
		z-index: 100;
		position: fixed;
	}
	.pd_header_list_btn {
		width: 20px;
		height: 20px;
		margin-top: 15px;
		margin-left: calc(100vw * 0.9);
	}
	.pd_header_list_btn img {
		width: 100%;
		height: 100%;
	}
	.tab_actionItem_line_2 {
		width: 100%;
		height: 1px;
		margin-top: 30px;
		/* margin-left: 10%; */
		margin-right: 10%;
		background: #e7e7e7;
	}
	.pd_header_list_1 {
		margin-top: 20px;
	}
	.tab_actionItem_pd {
		width: 90vw;
		height: 60px;
		margin-left: 5vw;
		display: flex;
		flex-direction: column;
	}
	.tab_actionItem_pd span {
		font-family: D-DIN;
		font-size: 18px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #999999;
	}

	.pd_header_list_1 .tab_actionItem_line_3 {
		display: none;
	}

	.pd_header_list_1 .active span {
		font-family: DINPro-Bold;
		font-weight: bold;
		color: #333333;
	}

	.pd_header_list_1 .active .tab_actionItem_line_3 {
		display: flex;
		width: 60px;
		height: 2px;
		margin-top: -1px;
		margin-right: 10%;
		background: #333333;
	}

	.tab_actionItem_line_1 {
		width: 100%;
		height: 1px;
		margin-top: 20px;
		margin-right: 10%;
		background: #e7e7e7;
	}
}
</style>
